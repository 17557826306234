<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{
              name: 'OrganizationHome',
            }">組織資料設定</b-breadcrumb-item>
            <b-breadcrumb-item active>首頁</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card v-if="showOrgCard" :title="`${organization.name}組織管理`" class="grid-margin">
      <b-button-group class="mb-4">
        <b-button v-if="$permissions.has($permissions.consts.ORGANIZATION_VARIABLE)" :to="{
              name: 'OrganizationVariableList',
              params: { org_code: $route.params.org_code },
            }">組織參數管理</b-button>
        <b-button v-if="$permissions.has($permissions.consts.ROLE_LIST_VIEW)" :to="{
              name: 'RoleList',
              params: { orgId: organization.id },
            }">角色權限管理</b-button>
        <b-button v-if="$permissions.has($permissions.consts.MODULE_LIST_VIEW)" :to="{
              name: 'ModuleList',
              params: { orgId: organization.id },
            }">模組管理</b-button>
        <b-button v-if="$permissions.has($permissions.consts.KEYWORD_LIST_VIEW)" :to="{ name: 'KeywordList' }">
          關鍵字管理
        </b-button>
        <b-button v-if="$permissions.has($permissions.consts.SQL_RUNNER_VIEW)" :to="{ name: 'SqlRunnerList' }">
          SQL 排程管理
        </b-button>
      </b-button-group>

      <div class="row">
        <div class="col-9 col-xl-6">
          <b-form-group label-cols-lg="2" label="組織名稱">
            <b-form-input type="text" :value="organization.name" :disabled="true"></b-form-input>
          </b-form-group>
          <b-form-group label-cols-lg="2" label="組織代碼">
            <b-form-input type="text" :value="organization.code" :disabled="true"></b-form-input>
          </b-form-group>
          <b-form-group label-cols-lg="2" label="組織編號">
            <b-form-input type="text" :value="organization.id" :disabled="true"></b-form-input>
          </b-form-group>
        </div>
      </div>
    </b-card>

    <b-card class="grid-margin" v-if="hasMerchantPermission">
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="mb-2 mb-xl-0 font-weight-bold">通路清單</h4>
        <b-button v-if="checkPermission([consts.MERCHANT_MODIFY])" class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
          variant="primary" :to="{ name: 'MerchantCreate' }">
          <i class="fa fa-plus"></i>新增通路
        </b-button>
      </div>

      <b-table striped hover responsive :items="merchants" :fields="fields">
        <template #cell(actions)="data">
          <b-button-group>
            <b-button v-if="checkPermission([consts.ORGANIZATION_MERCHANT_VARIABLE])" title="設定檔管理" v-b-tooltip.hover
              class="icon-btn" :to="{
              name: 'OrganizationVariableListByMerchant',
              params: { merchant_id: data.item.id },
            }">
              <BIconGear />
            </b-button>
            <b-button v-if="checkPermission([consts.ORGANIZATION_MERCHANT_TEMPLATE])" title="通知範本管理" v-b-tooltip.hover
              class="icon-btn" :to="{
              name: 'TemplateList',
              params: { merchant_id: data.item.id },
            }">
              <BIconChatLeftText />
            </b-button>
            <b-button title="模組管理" v-b-tooltip.hover class="icon-btn">
              <BIconBox />
            </b-button>
            <b-button v-if="data.item.type === 'LINE'" v-b-tooltip.hover title="更新 OA" class="icon-btn"
              @click="updateOaOnly(data.item.id)">
              <BIconCloudDownload />
            </b-button>
            <b-button v-if="data.item.type === 'LINE'" v-b-tooltip.hover title="更新好友資料" class="icon-btn"
              @click="updateFriends(data.item.id)">
              <BIconPeople />
            </b-button>
            <b-button title="查看" v-b-tooltip.hover class="icon-btn" :to="{
              name: 'MerchantShow',
              params: { merchant_id: data.item.id },
            }">
              <BIconEye />
            </b-button>
            <b-button v-if="checkPermission([consts.MERCHANT_MODIFY])" title="編輯" v-b-tooltip.hover class="icon-btn"
              :to="{
              name: 'MerchantEdit',
              params: { merchant_id: data.item.id },
            }">
              <BIconPencil />
            </b-button>
            <b-button v-if="checkPermission([consts.MERCHANT_DELETE])" title="刪除" v-b-tooltip.hover class="icon-btn"
              @click="handleDelete(data.item)">
              <BIconTrash />
            </b-button>
            <b-tooltip target="tooltip-target-1" triggers="hover">
              設定檔管理
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  BIconGear,
  BIconChatLeftText,
  BIconBox,
  BIconCloudDownload,
  BIconEye,
  BIconPencil,
  BIconTrash,
  BIconPeople,
} from "bootstrap-vue";
import { format } from "date-fns";
import merchantApi from "../../../apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  components: {
    BIconGear,
    BIconChatLeftText,
    BIconBox,
    BIconCloudDownload,
    BIconPeople,
    BIconEye,
    BIconPencil,
    BIconTrash,
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "id",
          label: "編號",
        },
        {
          key: "type",
          label: "類型",
        },
        {
          key: "is_enabled",
          label: "狀態",
          formatter: (value) => {
            if (parseInt(value, 10) === 0) {
              return '已停用'
            }
            if (parseInt(value, 10) === 1) {
              return '啟用'
            }

            return value
          }
        },
        {
          key: "updated_at",
          label: "更新日",
          formatter: (value) => format(new Date(value), "yyyy-MM-dd HH:mm"),
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      merchants: [],
      consts: consts,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    hasMerchantPermission() {
      return this.$permissions.has(this.$permissions.consts.MERCHANT_LIST_VIEW);
    },
    showOrgCard() {
      return this.$permissions.has(this.$permissions.consts.ORGANIZATION_VARIABLE) ||
        this.$permissions.has(this.$permissions.consts.ROLE_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.MODULE_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.KEYWORD_LIST_VIEW) ||
        this.$permissions.has(this.$permissions.consts.SQL_RUNNER_VIEW)
    },
  },
  mounted() {
    if (this.hasMerchantPermission) {
      this.fetchMerchants();
    }
  },
  methods: {
    ...mapActions("general", []),
    format,
    async fetchMerchants(page) {
      this.showLoading = true;

      const params = {
        page: page || this.currentPage,
      };
      if (this.keyword) params.keyword = this.keyword;

      try {
        const { data } = await merchantApi.list(this.organization.id);
        this.merchants = data.data;
        this.totalRows = data.meta.total;
        this.perPage = data.meta.per_page;
      } catch (error) {
        console.log(error);
        alert("取得 merchant 錯誤");
      } finally {
        this.showLoading = false;
      }
    }, // end fetchMerchants
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async updateOa(merchant_id) {
      this.showLoading = true;

      try {
        const { data } = await merchantApi.updateOa(merchant_id);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line OA 成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步 Line OA 失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line OA 失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    async updateOaOnly(merchant_id) {
      this.showLoading = true;

      try {
        const { data } = await merchantApi.updateOaOnly(merchant_id);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line OA 成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步 Line OA 失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line OA 失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    async updateFriends(merchant_id) {
      this.showLoading = true;

      try {
        const { data } = await merchantApi.updateFriends(merchant_id);
        if (data.status === "success") {
          this.$swal.fire({
            title: "同步 Line 好友成功",
            type: "success",
          });
        } else {
          this.$swal.fire({
            title: "同步 Line 好友失敗",
            type: "error",
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "同步 Line 好友失敗",
          type: "error",
        });
      } finally {
        this.showLoading = false;
      }
    },
    handleCreate() {
      this.$router.push({
        name: "MerchantCreate",
      });
    },
    async handleDelete(merchant) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>通路名稱：${merchant.name}</div>
                <div>通路類型：${merchant.type}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.showLoading = true;
              await merchantApi.deleteMerchant(
                this.organization.id,
                merchant.id
              );
              await this.fetchMerchants();
            } catch (error) {
              console.log("");
            }
            this.showLoading = false;
          }
        });
    },
  }, // end methods
};
</script>

<style scoped lang="scss">
.tooltip {
  opacity: 1;
}

.grid-margin {
  .btn+.btn {
    margin-left: 0.5rem;
  }
}
</style>
