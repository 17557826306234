var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'OrganizationHome',
          }}},[_vm._v("組織資料設定")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("首頁")])],1)],1)])]),(_vm.showOrgCard)?_c('b-card',{staticClass:"grid-margin",attrs:{"title":((_vm.organization.name) + "組織管理")}},[_c('b-button-group',{staticClass:"mb-4"},[(_vm.$permissions.has(_vm.$permissions.consts.ORGANIZATION_VARIABLE))?_c('b-button',{attrs:{"to":{
            name: 'OrganizationVariableList',
            params: { org_code: _vm.$route.params.org_code },
          }}},[_vm._v("組織參數管理")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.ROLE_LIST_VIEW))?_c('b-button',{attrs:{"to":{
            name: 'RoleList',
            params: { orgId: _vm.organization.id },
          }}},[_vm._v("角色權限管理")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.MODULE_LIST_VIEW))?_c('b-button',{attrs:{"to":{
            name: 'ModuleList',
            params: { orgId: _vm.organization.id },
          }}},[_vm._v("模組管理")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.KEYWORD_LIST_VIEW))?_c('b-button',{attrs:{"to":{ name: 'KeywordList' }}},[_vm._v(" 關鍵字管理 ")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.SQL_RUNNER_VIEW))?_c('b-button',{attrs:{"to":{ name: 'SqlRunnerList' }}},[_vm._v(" SQL 排程管理 ")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-9 col-xl-6"},[_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織名稱"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.name,"disabled":true}})],1),_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織代碼"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.code,"disabled":true}})],1),_c('b-form-group',{attrs:{"label-cols-lg":"2","label":"組織編號"}},[_c('b-form-input',{attrs:{"type":"text","value":_vm.organization.id,"disabled":true}})],1)],1)])],1):_vm._e(),(_vm.hasMerchantPermission)?_c('b-card',{staticClass:"grid-margin"},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"mb-2 mb-xl-0 font-weight-bold"},[_vm._v("通路清單")]),(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary","to":{ name: 'MerchantCreate' }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增通路 ")]):_vm._e()],1),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.merchants,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button-group',[(_vm.checkPermission([_vm.consts.ORGANIZATION_MERCHANT_VARIABLE]))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"設定檔管理","to":{
            name: 'OrganizationVariableListByMerchant',
            params: { merchant_id: data.item.id },
          }}},[_c('BIconGear')],1):_vm._e(),(_vm.checkPermission([_vm.consts.ORGANIZATION_MERCHANT_TEMPLATE]))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"通知範本管理","to":{
            name: 'TemplateList',
            params: { merchant_id: data.item.id },
          }}},[_c('BIconChatLeftText')],1):_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"模組管理"}},[_c('BIconBox')],1),(data.item.type === 'LINE')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"更新 OA"},on:{"click":function($event){return _vm.updateOaOnly(data.item.id)}}},[_c('BIconCloudDownload')],1):_vm._e(),(data.item.type === 'LINE')?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"更新好友資料"},on:{"click":function($event){return _vm.updateFriends(data.item.id)}}},[_c('BIconPeople')],1):_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"查看","to":{
            name: 'MerchantShow',
            params: { merchant_id: data.item.id },
          }}},[_c('BIconEye')],1),(_vm.checkPermission([_vm.consts.MERCHANT_MODIFY]))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"編輯","to":{
            name: 'MerchantEdit',
            params: { merchant_id: data.item.id },
          }}},[_c('BIconPencil')],1):_vm._e(),(_vm.checkPermission([_vm.consts.MERCHANT_DELETE]))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"icon-btn",attrs:{"title":"刪除"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_c('BIconTrash')],1):_vm._e(),_c('b-tooltip',{attrs:{"target":"tooltip-target-1","triggers":"hover"}},[_vm._v(" 設定檔管理 ")])],1)]}}],null,false,773726201)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }